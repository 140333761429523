<template>
  <v-card style="max-width: 900px; margin: 200px auto" class="pa-8">
    <v-card-title class="justify-center h1 mb-0">Accept Invitation</v-card-title>
    <hr class="mb-5" />
    <form @submit.prevent="submit">
      <v-row class="mx-0">
        <v-col cols="6">
          <v-text-field v-model="user.firstName" label="First Name" hide-details="auto" outlined required></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="user.lastName" hide-details="auto" outlined label="Last Name" required></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col>
          <v-text-field type="password" v-model="user.password" hide-details="auto" outlined label="Password" required></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col>
          <v-text-field disabled v-model="user.email" hide-details="auto" outlined label="Email" required></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mx-0">
        <v-col>
          <v-btn block color="#517FAB" dark class="mr-4" type="submit" :disabled="invalid">
            submit
          </v-btn>
        </v-col>
      </v-row>
    </form>
  </v-card>
</template>

<script>
import { userService } from "@/services";

export default {
  components: {},
  data: () => ({
    user: {
      firstName: "",
      lastName: "",
      email: "",
      key: "",
      password: "",
    },
  }),
  mounted() {
    const invitatationKey = this.$route.query.invitationKey;
    try {
      const invitation = JSON.parse(atob(invitatationKey));
      console.log(invitation);
      if (invitation.email) {
        this.user.email = invitation.email;
        this.user.key = invitation.key;
      }
    } catch (error) {
      console.error("Error processing invitation link");
    }
  },
  methods: {
    async submit() {
      try {
        //const res = await userService.accept(this.user);
        //redirect user to home page
        this.$router.push({ name: "home" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
